import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';

import Layout from '../components/layout';
import Container from '../components/container';
import PageTitle from '../components/page-title';
import ExpandableSection from '../components/expandable-section';

type CorporateCommercialOffers = {
  allCorporateCommercialOffersYaml: {
    edges: {
      node: {
        name: string;
        path: string;
      };
    }[];
  };
  allCorporateArchivalCommercialOffersYaml: {
    edges: {
      node: {
        year: string;
        items: {
          date: string;
          offers: {
            name: string;
            path: string;
          }[];
        }[];
      };
    }[];
  };
};

const query = graphql`
  query {
    allCorporateCommercialOffersYaml {
      edges {
        node {
          name
          path
        }
      }
    }
    allCorporateArchivalCommercialOffersYaml {
      edges {
        node {
          year
          items {
            date
            offers {
              name
              path
            }
          }
        }
      }
    }
  }
`;

const ConsumerCommerce: React.FC = () => {
  const data = useStaticQuery<CorporateCommercialOffers>(query);
  const corporateCommercialOffers = data.allCorporateCommercialOffersYaml.edges.map(({ node }) => node);
  const corporateArchivalCommercialOffers = data.allCorporateArchivalCommercialOffersYaml.edges.map(({ node }) => node);

  return (
    <Layout>
      <Container className="general">
        <PageTitle title="Договори та комерційні пропозиції" />

        <ul>
          <li>
            <a
              href={'/documents/contracts-and-commercial-offers/Договір про постачання електричної енергії споживачу (2).doc'}
              target="_blank"
              rel="noreferrer"
            >
              <b>Договір про постачання електричної енергії споживачу</b>
            </a>
          </li>
          <li>
            <a
              href={'/documents/contracts-and-commercial-offers/Договір про механізм самовиробництва (ВЦ) (3).doc'}
              target="_blank" rel="noreferrer">
              Договір про купівлю-продаж електричної енергії за механізмом самовиробництва (ВЦ)
            </a>
          </li>
          <li>
            <a
              href={'/documents/contracts-and-commercial-offers/Договір про постачання електричної енергії постачальником універсальних послуг (2).doc'}
              target="_blank"
              rel="noreferrer"
            >
              <b>Договір про постачання електричної енергії постачальником універсальних послуг</b>
            </a>
          </li>
          <li>
            <a
              href={'/documents/contracts-and-commercial-offers/Договір про механізм самовиробництва УП (4) 1.doc'}
              target="_blank" rel="noreferrer">
              Договір про купівлю-продаж електричної енергії за механізмом самовиробництва (УП)
            </a>
          </li>
          <li>
            <a
              className="inline-block mb-20"
              href="/documents/Порядок укладення договору.doc"
              target="_blank"
            >
              Як укласти договір непобутовим споживачам
            </a>
          </li>
          <li>
            <a
              className="inline-block mb-20"
              href="/documents/Механізм самовиробництва для непобутових споживачів за вільними цінами.pdf"
              target="_blank"
            >
              Механізм самовиробництва для непобутових споживачів за вільними цінами
            </a>
          </li>
        </ul>
        <h2>Комерційні пропозиції:</h2>
        <ul>
          {corporateCommercialOffers.map(({name, path}) => (
            <li>
              <a href={path} target="_blank" rel="noreferrer">
                {name}
              </a>
            </li>
          ))}
        </ul>

        <h2>Архівні договори та комерційні пропозиції</h2>

        {corporateArchivalCommercialOffers.map(({year, items}) => (
          <ExpandableSection summary={year}>
            {items.map(({date, offers}) => (
              <ExpandableSection summary={date}>
                <ul>
                  {offers.map(({name, path}) => (
                    <li>
                      <a href={path} target="_blank" rel="noreferrer">
                        {name}
                      </a>
                    </li>
                  ))}
                </ul>
              </ExpandableSection>
            ))}
          </ExpandableSection>
        ))}
      </Container>
    </Layout>
  );
};

export default ConsumerCommerce;
